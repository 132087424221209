import { navigate } from 'gatsby';
import React from 'react';

import phpScriptsUrl from '../../phpScriptsUrl';
import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/answer.module.scss';
import { getUserData } from '../utils/userData';

export default class Final extends React.Component {
  componentDidMount() {
    if (
      typeof window !== `undefined` &&
      (!this.props.location.state || !getUserData())
    ) {
      return navigate(`/lessons`)
    }

    this.sendEmailToUser({
      baseUrl: `${this.props.location.protocol}//${this.props.location.host}`,
      email: getUserData().email,
    })
  }

  formEncode(obj) {
    var str = []
    for (var p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    return str.join('&')
  }

  sendEmailToUser = data => {
    window.fetch(`${phpScriptsUrl}certificate.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: this.formEncode(data),
    })
  }

  render() {
    if (typeof window !== `undefined` && !this.props.location.state) {
      return <Layout />
    }

    return (
      <Layout>
        <Seo
          title="Consider yourself a Pilsner Urquell expert!"
          description="You must be thirsty after all that thinking. How about a Hladinka? You deserve it. Na zdraví!"
        />

        <Page type="success">
          <Header closeUrl="/lessons">FINAL CORRECT ANSWER</Header>
          <Content>
            <div className="final-mobile">
              <h1 className={`h2 less-growing text-transform-uppercase`}>
                CONGRATULATIONS! YOU&nbsp;PASSED ALL THE TESTS&nbsp;AND BECAME
                A&nbsp;Czech beer AMBASSADOR
              </h1>
              <div className="page-text-container">
                <div className={`h6 ${styles.answer__text}`}>
                  You will find your certificate in your email.
                </div>
              </div>
            </div>
            <div className="final-desktop">
              <h1 className={`h2 less-growing text-transform-uppercase`}>
                CONGRATULATIONS! <br />
                YOU PASSED ALL <br />
                THE&nbsp;TESTS
              </h1>
              <div className="page-text-container">
                <h2>
                  AND BECAME <br />A CZECH BEER AMBASSADOR
                </h2>
                <div className={`h6 ${styles.answer__text}`}>
                  You will find your certificate <br />
                  in your email.
                </div>
              </div>
            </div>
          </Content>
          <Footer>
            <FooterLinkNext to="/lessons">Back to homepage</FooterLinkNext>
          </Footer>
        </Page>
      </Layout>
    )
  }
}
